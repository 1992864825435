<template>
  <main-header :type="'empty'" />
  <router-view></router-view>
  <loading></loading>
</template>

<script>

import MainHeader from './Header'
import Loading from '@/components/common/Loading.vue'

export default {
  name: 'MobileEmptyLayout',
  components: {
    MainHeader,
    Loading
  }
}
</script>

<style>

</style>
